<template>
  <v-dialog v-model="value" persistent width="500px">
    <v-form v-model="valid" ref="twoFactorMethodForm">
      <v-card class="w-100" outlined>
        <v-card-title>
          <h4 class="primary--text">
          {{ $t('login.chooseTwoFactorMethod') }}
          </h4>
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-if="twoFactorMethods.phoneNumber"
            hide-details
            v-model="form.sendSms"
            :label="smsLabel"
            :rules="rules"
          >

          </v-checkbox>
          <v-checkbox
            v-if="twoFactorMethods.email"
            v-model="form.sendEmail"
            :label="emailLabel"
            :rules="rules"
          >

          </v-checkbox>
          <v-card-actions class="px-0 d-flex">
            <v-btn
              color="primary"
              outlined
              class="ma-2"
              @click="cancel()"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              :disabled="!valid"
              class="ml-auto"
              @click="submit()"
              depressed
            >
              {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import services from '@/services'

export default {
  name: 'TwoFactorMethod',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loginData: {
      type: Object,
      default: () => ({
        email: null,
        password: null
      }),
      required: true
    },
    twoFactorMethods: {
      type: Object,
      default: () => ({
        phoneNumber: null,
        email: null
      }),
      required: true
    }
  },
  data () {
    return {
      valid: true,
      form: {
        sendSms: false,
        sendEmail: false
      }
    }
  },
  created () {
    this.form.sendSms = !!this.twoFactorMethods.phoneNumber
    this.form.sendEmail = !!this.twoFactorMethods.email
  },
  computed: {
    smsLabel () {
      return `${this.$t('login.sms')} (${this.twoFactorMethods.phoneNumber})`
    },
    emailLabel () {
      return `${this.$t('login.email')} (${this.twoFactorMethods.email})`
    },
    rules () {
      return [
        (this.form.sendSms || this.form.sendEmail) || this.$t('validation.atLeastOneMethodRequired')
      ]
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
      this.$refs.twoFactorMethodForm.resetValidation()
    },
    async submit () {
      this.$refs.twoFactorMethodForm.validate()

      if (!this.valid) {
        return
      }

      try {
        const { data } = await services.post('Authentication/GenerateTwoFactorCode', {
          email: this.loginData.email,
          password: this.loginData.password,
          sendSms: this.form.sendSms,
          sendEmail: this.form.sendEmail
        })

        sessionStorage.setItem('maskedPhone', data.phoneNumber)
        sessionStorage.setItem('maskedEmail', data.email)
        sessionStorage.setItem('email', this.loginData.email)
        sessionStorage.setItem('password', this.loginData.password)
        sessionStorage.setItem('twoFactorAuthExpiryDate', data.twoFactorAuthExpiryDate)

        await this.$router.push({ name: 'TwoFactorAuthentication' })
        this.$emit('close')

        this.$refs.twoFactorMethodForm.resetValidation()
      } catch (e) {}
    }
  }
}
</script>
